<!--大厦index-->
<template>
  <!--  功能区  -->
  <div class="giEdificeDiv">
    <el-row class="outerRow">
      <el-col>
        <top-path-list :path-obj="pathObj"></top-path-list>
      </el-col>
    </el-row>
    <el-row class="outerRow">
      <el-col>
        <el-row>
          <div class="rowSpaceBetween">
            <span class="partTitle">展览安排</span><span class="moreData cursorPointer" @click="goDetail({pageUrl: '/giEdifice/moreShow'})">更多>></span>
          </div>
          <el-row style="height: 360px; margin-bottom: 10px; background: #fff; ">
            <el-col :span="12" style="height: 100%">
              <!--<div class="leftContainer">
                <div class="topDiv flexJustifyContentCenter">
                  <div class="backgroundTop ">
                    <span class="titleClass">今日展览</span>
                  </div>
                </div>
                <el-carousel class="carouselClass" arrow="never" >
                  <el-carousel-item v-for="( item, index) in carouselList" :key="index" class="flexAlignItemCenter">
                    <div class="carouselDataDiv">
                      <div class="carouselTitleClass">{{item.infoName}}</div>
                      <div class="carouselTimeClass flexAlignItemCenter">
                        <img src="@/assets/img/giEdifice/timer.svg" alt="" class="imgClass">
                        <span>{{item.showTime}}</span>
                      </div>
                      <div class="addrDiv">
                        <span class="carouselAddrClass" :title="item.infoPosition">{{item.infoPosition}}</span>
                      </div>
                    </div>
                  </el-carousel-item>
                </el-carousel>
              </div>-->
              <!--需求变动，改为图片标题轮播-->
              <el-carousel class="carouselNews" indicator-position="inside">
                <el-carousel-item v-for="(item,index) in showList" :key="index">
                  <img :src="item.coverImageUrl" alt="" class="top-img" @click="toDetail(item)">
                  <div class="downShadowDiv">
                    <div class="downShadowText">
                      <span class="downSpanClass">{{item.infoName}}</span>
                    </div>
                  </div>
                </el-carousel-item>
              </el-carousel>

            </el-col>
            <el-col :span="12">
              <div v-for="(item, index) in showList" :key="index" class="rowSpaceBetween listDiv" @click="toDetail(item)">
                <div class="flexAlignItemCenter">
                  <div class="circleIcon"></div>
                  <div class="listTitle">{{item.infoName}}</div>
                </div>
                <div class="listTime">
                  {{item.showTime && item.showTime.length > 0 ? item.showTime.substring(0, 21) : ''}}
                </div>
              </div>
            </el-col>
          </el-row>
        </el-row>
        <el-row>
          <div class="partTitle">地标大厦</div>
          <div class="iconContainer">
            <div class="infoDiv colFlexCenter cursorPointer"
                 v-for="(item, index) in iconList" :key="index" @click="goDetail(item)">
              <div class="iconDiv colFlexCenter flexAlignItemCenter">
                <img :src="item.imgSrc" alt="" class="imgClass" />
                <div class="infoTitle">{{ item.name }}</div>
              </div>
            </div>
          </div>
        </el-row>
        <el-row style="margin-bottom: 10px">
          <div class="partTitle">大厦位置</div>
          <gaode-map></gaode-map>
        </el-row>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import topPathList from '@/components/topPathList'
import gaodeMap from '@/components/gaodeMap'
import axios from 'axios'
import eventBus from "@/utils/eventBus.js";

export default {
  name: 'index',
  components: { topPathList, gaodeMap },
  data() {
    return {
      pathObj: [
        {
          name: '首页',
          path: '/'
        },
        {
          name: '地标大厦',
          path: '/giEdifice/index'
        },
      ],
      iconList: [
        {name: '大厦设施', imgSrc: require('@/assets/img/giEdifice/facilities.png'), pageUrl: '/giEdifice/facilities'},
        {name: '展览报名', imgSrc: require('@/assets/img/giEdifice/application.png'), pageUrl: ''},
        {name: '在线预约', imgSrc: require('@/assets/img/giEdifice/online.png'), pageUrl: '/giEdifice/online'},
        {name: '大厦服务', imgSrc: require('@/assets/img/giEdifice/items.png'), pageUrl: '/giEdifice/serviceItems'},
        {name: '周边配套', imgSrc: require('@/assets/img/giEdifice/services.png'), pageUrl: '/giEdifice/buildingService'},
        {name: '下载中心', imgSrc: require('@/assets/img/giEdifice/download.png'), pageUrl: '/giEdifice/downloadCenter'},
        {name: '办展流程', imgSrc: require('@/assets/img/giEdifice/process.png'), pageUrl: '/giEdifice/process'},
        {name: '管理规定', imgSrc: require('@/assets/img/giEdifice/regulations.png'), pageUrl: '/giEdifice/regulations'},
        {name: '收费标准', imgSrc: require('@/assets/img/giEdifice/fees.png'), pageUrl: '/giEdifice/chargingStandards'},
        /*{name: '意见反馈', imgSrc: require('@/assets/img/giEdifice/feedback.png'), pageUrl: '/giEdifice/feedback'},*/
        {name: '联系我们', imgSrc: require('@/assets/img/giEdifice/contactUs.png'), pageUrl: '/giEdifice/contactUs'},
      ],
      leftMenu: [
        { name: '大厦介绍' },
        { name: '大厦设施' },
        { name: '办展流程' },
        { name: '意见反馈' },
      ],
      menuIndex: 0,
      menuName: '',
      showList: [],
      carouselList: [],
    }
  },
  mounted () {
    this.menuName = this.leftMenu[0].name;
    this.initData()
    eventBus.$off('toNewPath')
    eventBus.$on('toNewPath',( title)=>{
      this.goDetail({name: '展览报名'})
    })
  },
  methods: {
    //跳转详情
    toDetail(item) {
      this.$router.push({
        path: '/giEdifice/showDetail',
        query: {
          infoId: item.infoId,
        }
      });
    },

    clickMenu({ menu, index }) {
      this.menuName = menu.name;
      this.menuIndex = index
    },
    goDetail(data) {
      if (data.name == "展览报名") {
        if (sessionStorage.getItem("HussarToken")) { //已登录
          window.open(process.env.VUE_APP_Back_PATH + 'contentManage/exhibitionregistration/exhibitionregistrationForm', '_blank')
        } else {
          //弹出登录弹窗
          eventBus.$emit('toLogin');
        }
      } else {
        this.$router.push({
          path: data.pageUrl,
        })
      }
    },
    initData() {
      /*axios({
        method: "get",
        url: process.env.VUE_APP_HUSSAR_DEFAULT_API + "/api/portal/exhibit/show",
      }).then(res => {
        if (res.data.code === 10000 || res.data.code === 200) {
          this.carouselList = res.data.data
        } else {
          this.$message.error('查询失败')
        }
      }).catch(err => {
        this.$message.error('接口调用异常')
      })*/

      let data = {
        current: 1,
        size: 6,
        type: 'all',
      }
      axios({
        method: "get",
        url: process.env.VUE_APP_HUSSAR_DEFAULT_API + "/api/portal/exhibit/all",
        params: data,
      }).then(res => {
        if (res.data.code === 10000 || res.data.code === 200) {
          this.showList = res.data.data.records;
        } else {
          this.$message.error('查询失败')
        }
      }).catch(err => {
        this.$message.error('接口调用异常')
      })
    },
  }
}
</script>

<style scoped lang="less">
@import '~@/assets/css/common.less';
.giEdificeDiv {
  background-color: #f8f9fa !important;
}
.partTitle {
  font-size: 20px;
  font-family: SourceHanSansCN-Bold, SourceHanSansCN;
  font-weight: bold;
  color: #333333;
  line-height: 30px;
  margin-bottom: 15px;
}
.moreData {
  font-size: 14px;
  font-family: SourceHanSansCN-Regular, SourceHanSansCN;
  font-weight: 400;
  color: #7EA1DB;
  line-height: 21px;
  display: flex;
  align-items: end;
}

.carouselNews {
  float: right;
  border-radius: 5px;
  height: 360px;
  width: 590px;
  .top-img {
    width: 100%;
    height: 100%;
    border-radius: 5px;
  }
  .downShadowDiv {
    height: 48px;
    background: rgba(0,0,0,0.4);
    border-radius: 0px 0px 5px 5px;
    position: relative;
    bottom: 53px;
    text-align: left;
    padding-left: 15px;
    padding-top: 16px;
    .downShadowText {
      width: 377px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      .downSpanClass {
        font-size: 16px;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        color: #FFFFFF;
      }
    }
  }
}

.listDiv {
  margin: 30px 15px;

  .circleIcon {
    width: 6px;
    height: 6px;
    background: #BECDE6;
    border: 1px solid #DFEBFF;
    border-radius: 3px;
    margin: 0 8px;
  }
  .listTitle {
    font-size: 16px;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #333333;
    line-height: 24px;
  }
  .listTime {
    font-size: 14px;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #999999;
    line-height: 21px;
  }
}
.listDiv:hover {
  cursor: pointer;
  .circleIcon {
    width: 6px;
    height: 6px;
    border: 1px solid #DFEBFF;
    border-radius: 3px;
    margin: 0 8px;
    background: #528ffa;
  }
  .listTitle {
    font-size: 16px;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    line-height: 24px;
    color: #528ffa;
  }
}
.leftContainer {
  width: 100%;
  height: 100%;
  background-image: url("~@/assets/img/giEdifice/carouselBackground.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;

  .carouselClass {
    position: relative;
    top: -13%;

    .carouselDataDiv {
      width: 100%;
      height: 50%;
    }
    .imgClass {
      width: 16px;
      height: 16px;
      margin: 0 16px 0 0;
    }
    .carouselTitleClass {
      font-size: 28px;
      font-family: SourceHanSansCN-Bold, SourceHanSansCN;
      font-weight: bold;
      color: #072555;
      line-height: 42px;
      margin: 0 90px;
    }
    .carouselTimeClass {
      font-size: 18px;
      font-family: ArialMT;
      color: #186CF5;
      line-height: 21px;
      margin: 20px 90px 15px;
    }
    .addrDiv {
      margin: 0 90px;
      width: 450px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .carouselAddrClass {
      background: #4E8FF3;
      border-radius: 5px;
      font-size: 18px;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      font-weight: 400;
      color: #FFFFFF;
      line-height: 32px;
      padding: 7px;
    }

    /*::v-deep .el-carousel__button {
      width: 13px;
      height: 13px;
      border-radius: 7px;
    }
    ::v-deep .el-carousel__indicators--horizontal {
      right: 20px!important;
      bottom: 10px;
      left: auto;
      transform: translateX(-30%);
    }*/
  }

  .topDiv {
    height: 13%;
    width: 100%;

    .backgroundTop {
      width: 33%;
      height: 100%;
      background-image: url("~@/assets/img/giEdifice/titleBackgrund.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .titleClass {
      font-size: 20px;
      font-family: SourceHanSansCN-Medium, SourceHanSansCN;
      font-weight: 500;
      color: #FFFFFF;
      line-height: 30px;
    }
  }
}

.iconContainer {
  width: 100%;
  height: 360px;
  display: grid;
  grid-template-columns: auto auto auto auto auto;
  //grid-gap: 30px 50px;
  margin-bottom: 15px;
  background: white;

  .infoDiv {
    margin: 10px 20px;

    .iconDiv {
      background: #F1F7FF;
      border-radius: 5px;
      height: 90%;
    }

    .imgClass {
      width: 54px;
      height: 54px;
    }

    .infoTitle {
      font-size: 18px;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      font-weight: 400;
      color: #333333;
      line-height: 27px;
      margin: 10px 0 0;
    }
  }
}

::v-deep .el-carousel__container {
  height: 360px;
}
//轮播图指示器设置为圆点
::v-deep .el-carousel__indicator--horizontal .el-carousel__button {
  width: 10px;
  height: 10px;
  background: #dee9f6;
  border: 1px solid #dee9f6;
  border-radius: 50%;
  opacity: 0.5;
}
::v-deep .el-carousel__indicator--horizontal.is-active .el-carousel__button{
  width: 10px;
  height: 10px;
  background: #FFB74C;
  border-radius: 50%;
  opacity: 1;
}
::v-deep .el-carousel__indicators--horizontal {
  left: 81% !important;
}
</style>
