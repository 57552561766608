<!--高德地图-->
<template>
  <el-row>
    <el-col :span="17">
      <div class="mapDiv">
        <div id="container"></div>
      </div>
    </el-col>
    <el-col :span="6" :offset="1">
      <el-row>
        <span class="titleClass">地理标志产业大厦</span>
      </el-row>
      <el-row style="margin: 10px 0">
        <span class="addrClass">济南市槐荫区济西南路14号济南西站-东广场</span>
      </el-row>
      <el-row style="margin: 10px 0">
        <el-col :span="12">
          <div @click="handleClick('start')" class="cursorPointer flexACenterJCenter"
               :class="activeName === 'start' ? 'activeDivLeft' : 'unActive'">
            <img src="@/assets/img/giEdifice/redCircle.png" class="circleClass"
                 alt="" v-show="activeName === 'start'">
            <img src="@/assets/img/giEdifice/greenCircle.png"  class="circleClass"
                 alt="" v-show="activeName === 'end'">
            <span class="tabFont">到这里去</span>
          </div>
        </el-col>
        <el-col :span="12">
          <div @click="handleClick('end')" class="cursorPointer flexACenterJCenter"
               :class="activeName === 'end' ? 'activeDivRight' : 'unActive'">
            <img src="@/assets/img/giEdifice/redCircle.png" class="circleClass"
                 alt="" v-show="activeName === 'end'">
            <img src="@/assets/img/giEdifice/greenCircle.png"  class="circleClass"
                 alt="" v-show="activeName === 'start'">
            <span class="tabFont">从这里出发</span>
          </div>
        </el-col>
      </el-row>
      <div class="gridColumn">
        <el-input id="tipInput" v-model="keyword" :placeholder="placeholder" clearable @change="inputChange"
                  @select="inputSelect" style="width: auto;"></el-input>
        <el-button class="moreBtn" @click="byBus" style="width: auto;margin-left: 5px">
          <span>公交</span>
        </el-button>
        <el-button class="moreBtn" @click="byCar" style="width: auto;margin-left: 5px">
          <span>驾车</span>
        </el-button>
      </div>
      <div id="panel"></div>
    </el-col>
  </el-row>
</template>

<script>
import AMapLoader from '@amap/amap-jsapi-loader';
// 设置安全密钥
window._AMapSecurityConfig = {
  securityJsCode: "8bd7f6a15ed283d5eb6998818821ba15",
};

export default {
  name: 'gaodeMap',
  data() {
    return {
      map: null,
      activeName: 'start',
      placeholder: '输入起点',
      keyword: '',
      driving: null,
      transfer: null,
      transportation: '',
      centerPoint: [116.8968,36.668441], // 地图中心点
    }
  },
  created () {
    this.initialLoad();
  },
  mounted () {
    this.initialLoad();
  },
  methods: {
    handleClick(tab, event) {
      this.activeName = tab;
      tab === 'start' ? this.placeholder = '输入起点' : this.placeholder = '输入终点'
      if (this.transportation){
        this.transportation === 'bus' ? this.byBus() : this.byCar();
      }
    },
    inputSelect({id,name,adcode,district,location,type}) {

    },
    initialLoad(){
      let that = this;
      AMapLoader.load({
        key: "63baf96260ec54ee977c50ba2679ce8f", //此处填注册账号后获取的Key
        version: "2.0", //指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        plugins: [
          "AMap.AutoComplete", // 输入提示，提供了根据关键字获得提示信息的功能
          "AMap.Driving", // 驾车路线规划服务，提供按照起、终点进行驾车路线的功能
          "AMap.Transfer", // 公交路线规划服务，提供按照起、终点进行公交路线的功能
          "AMap.Marker",
          "AMap.Geocoder",
        ], //需要使用的的插件列表，如比例尺'AMap.Scale'等
      }).then((AMap) => {
        that.map = new AMap.Map("container", { //设置地图容器id
          zoom: 18, //初始化地图级别
          center: that.centerPoint, //初始化地图中心点位置
          lang:"en",
        });

        // 输入提示
        var auto = new AMap.Autocomplete({
          city: '济南',
          input: 'tipInput'
        });

        that.map.add(auto)

        // 大厦锚点
        let marker = new AMap.Marker({
          position: that.centerPoint, // 基点位置
          offset: new AMap.Pixel(0,0), // 设置点标记偏移量
          anchor:'center', // 设置锚点方位
        });
        that.map.add(marker);

        // 公交
        var transOptions = {
          map: that.map,
          city: '济南市',
          panel: 'panel',
          policy: AMap.TransferPolicy.LEAST_TIME //乘车策略
        };
        //构造公交换乘类
        that.transfer = new AMap.Transfer(transOptions);

        // 驾车路线规划策略
        that.driving = new AMap.Driving({
          map: that.map,
          // AMap.DrivingPolicy.LEAST_TIME是最快捷模式
          policy: AMap.DrivingPolicy.LEAST_TIME
        })
      }).catch(e => {
        console.log(e);
      })

    },
    byBus() {
      const that = this;
      that.driving.clear();
      if (!that.keyword) {
        that.transfer.clear();
        that.resetMap()
        return
      }
      that.transportation = 'bus'
      let lines = null;
      if (this.activeName === 'start') {
        lines = [{ keyword: this.keyword }, { keyword: '济南西站东广场' }] //city缺省时取transOptions的city属性
      } else {
        lines = [{ keyword: '济南西站东广场' }, { keyword: this.keyword }]
      }

      //根据起、终点名称查询公交换乘路线
      that.transfer.search(lines, function(status, result) {
        // result即是对应的公交路线数据信息，相关数据结构文档请参考  https://lbs.amap.com/api/javascript-api/reference/route-search#m_TransferResult
        if (status === 'complete') {

        } else {
          console.error('公交路线数据查询失败' + result)
        }
      });
    },
    byCar() {
      const that = this;
      that.transfer.clear();
      if (!that.keyword) {
        that.driving.clear();
        that.resetMap()
        return
      }
      that.transportation = 'car'
      let points;
      if (this.activeName === 'start') {
        points = [{ keyword: this.keyword }, { keyword: '济南西站东广场' }] //city缺省时取transOptions的city属性
      } else {
        points = [{ keyword: '济南西站东广场' }, { keyword: this.keyword }]
      }
      that.driving.search(points, (status, result) => {

      })
    },
    // 重置地图位置以及视角
    resetMap() {
      const that = this;
      that.map.setZoomAndCenter(18, that.centerPoint)
    },
    inputChange() {
      const that = this;
      if (!that.keyword) {
        that.driving.clear();
        that.transfer.clear();
        that.resetMap()
      }
    }
  }
}
</script>

<style scoped lang="less">
@import '~@/assets/css/common.less';
#container {
  width: 100%;
  height: 100%;
}
.titleClass {
  font-size: 18px;
  font-family: SourceHanSansCN-Bold, SourceHanSansCN;
  font-weight: bold;
  color: #333333;
  line-height: 27px;
}
.addrClass {
  font-size: 14px;
  font-family: SourceHanSansCN-Regular, SourceHanSansCN;
  font-weight: 400;
  color: #666666;
  line-height: 21px;
}
.introduceDetail {
  font-size: 16px;
  font-family: SourceHanSansCN-Regular, SourceHanSansCN;
  font-weight: 400;
  color: #3E3A39;
  line-height: 30px;
  text-align: left;
  text-indent: 2rem; // 首行缩进2字符
}
.mapDiv {
  margin-bottom: 10px;
  height: 600px;
}
.circleClass {
  width: 12px;
  height: 12px;
  margin: 0 5px;
}
.activeDivLeft {
  height: 32px;
  border-top: 2px solid #EBF0F6;
  border-left: 2px solid #EBF0F6;

  .tabFont {
    font-size: 14px;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #666666;
    line-height: 21px;
  }
}
.activeDivRight {
  height: 32px;
  border-top: 2px solid #EBF0F6;
  border-right: 2px solid #EBF0F6;

  .tabFont {
    font-size: 14px;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #666666;
    line-height: 21px;
  }
}
.unActive {
  height: 32px;
  background: #F9FBFF;
  border: 2px solid #EBF0F6;
  .tabFont {
    font-size: 14px;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #888888;
    line-height: 21px;
  }
}
</style>
